import React from "react";

import { navigate } from "gatsby";

import { StyledButtonPill } from "../layout/button";

import { Price, PriceDetailsWrapper, PriceWrapper, Wrapper } from "./styles";

export type PricingType = "SUPERIOR" | "PREMIUM" | "BASE";

export interface Price {
  oneYear: number;
  sixMonths: number;
  threeMonths: number;
  oneMonth: number;
}

export interface PricingBoxProps {
  price: number;
  type: PricingType;
  sessions: number | "UNLIMITED";
}

const getLabel = (type: PricingType) => {
  switch (type) {
  case "PREMIUM":
    return "Premium";
  case "SUPERIOR":
    return "Supérieur";
  case "BASE":
    return "Base";
  default:
    break;
  }
};

const onClick = () => navigate("/contact#form");

export const PricingBox = ({ type, price, sessions }: PricingBoxProps) => {
  return (
    <Wrapper>
      <h3 style={{ marginBottom: "1rem" }}>{getLabel(type)}</h3>
      <PriceWrapper>
        <Price>{price}</Price>$
      </PriceWrapper>
      <PriceDetailsWrapper>
        <p>
          {sessions === "UNLIMITED"
            ? "Séances illimitées"
            : `${sessions} séances
          par mois`}
        </p>
      </PriceDetailsWrapper>
      <StyledButtonPill variant="contained" size="large" onClick={onClick}>
        Choisir
      </StyledButtonPill>
    </Wrapper>
  );
};
