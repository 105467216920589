import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
`;

export const PriceDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;

  ul {
    padding: 0;

    li {
      margin-bottom: 1rem;
    }
  }
`;

export const Price = styled.span`
  font-size: 4.5rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;
