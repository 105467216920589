import React from "react";

import styled from "@emotion/styled";
import { graphql, Link, useStaticQuery } from "gatsby";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

import { Container, Wrapper } from "../components/layout";
import { Layout } from "../components/layout/layout";
import {
  PricingBox,
  PricingBoxProps,
} from "../components/pricing-box/pricing-box";
import { FromLeft } from "../components/transitions";

const PriceTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8rem;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 6rem;
    margin-bottom: 2rem;
  }
`;

const prices: PricingBoxProps[] = [
  {
    type: "PREMIUM",
    sessions: "UNLIMITED",
    price: 135,
  },
  {
    type: "SUPERIOR",
    price: 115,
    sessions: 12,
  },
  {
    type: "BASE",
    price: 95,
    sessions: 8,
  },
];

const Pricing = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "abonnement.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout background={data.hero} title="Abonnement">
      <Helmet>
        <title>Centre Léveil - Abonnement</title>
      </Helmet>
      <Wrapper style={{ backgroundColor: "black" }}>
        <FromLeft>
          <Container direction="column">
            <h3 style={{ marginBottom: "2rem" }}>
              Nous offrons plusieurs options de tarification.
            </h3>
            <p style={{ marginInline: "auto" }}>
              Vous pouvez choisir entre un abonnement pour 8 séances, 12 séances
              ou un nombre illimité de séances par mois. Plusieurs cycles de
              paiement sont également disponibles.
            </p>
          </Container>
        </FromLeft>
      </Wrapper>
      <Wrapper>
        <PriceTableWrapper
          className={isMobile ? "mobile" : ""}
          style={{ marginBottom: "4rem" }}
        >
          {prices.map(({ type, price, sessions }) => (
            <PricingBox
              type={type}
              price={price}
              key={type}
              sessions={sessions}
            />
          ))}
        </PriceTableWrapper>
        <h2>À la carte</h2>
        <p style={{ fontWeight: "bold" }}>10 séances - 170$</p>
        <p style={{ fontWeight: "bold" }}>1 séance - 20$</p>
        <h4 style={{ marginTop: "20px" }}>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSctv64JiAZR_ThWW2Nf0xAzqDmHQbzZPuIFcOurLr-PaLgreg/viewform">
            Désabonnement
          </a>
        </h4>
      </Wrapper>
    </Layout>
  );
};

export default Pricing;
